class GalleryObserver {
    constructor() {
        this.gallery = document.querySelector('.picture-gallery')
        this.options = {
            threshold: 0.5,
         }
         this.observer = new IntersectionObserver((section, observer) => {
            let ratio = section[0].intersectionRatio
            if (!section[0].isIntersecting) {
                if(document.body.classList.contains('body--blue')) {
                    document.body.classList.remove('body--blue');
                }
                return;
            }
            if (ratio < 1) {
                this.changeBackground()
            }
          }, this.options);
        this.observer.observe(this.gallery)
    }
    changeBackground() {
       document.body.classList.add('body--blue')
    }

}

export default GalleryObserver;
