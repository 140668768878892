import Swiper from "swiper/bundle"
import GalleryObserver from "./modules/observer/galleryObserver"
import QuoteObserver from "./modules/observer/quoteObserver"

document.addEventListener("DOMContentLoaded", () => {
  // Add all your JS modules here:

  let galleryObserver = new GalleryObserver()
  let quoteObserver = new QuoteObserver()
})

const swiper = new Swiper(".hero__swiper-container .swiper", {
  // Optional parameters
  loop: true,
  allowTouchMove: false,
  autoplay: {
    delay: 3000,
  },
})

const pictureGallery = new Swiper(".picture-gallery__image-container .swiper", {
  loop: true,
  allowTouchMove: false,
  slidesPerView: 1.25,
  centeredSlides: true,
  spaceBetween: 10,

  breakpoints: {
    1024: {
      spaceBetween: 25,
      slidesPerView: 2,
    },
  },

  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
})

const quotes = new Swiper(".quote-slider__quote-container .swiper", {
  // autoHeight: true,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    type: "fraction",
  },
})
