class QuoteObserver {
    constructor() {
        this.slides = document.querySelector('.quote-slider')
        this.options = {
            threshold: 0.675,
         }
         this.observer = new IntersectionObserver((section, observer) => {
            let ratio = section[0].intersectionRatio
            if (!section[0].isIntersecting) {
                if(document.body.classList.contains('body--white')) {
                    document.body.classList.remove('body--white');
                }
                return;
            }
            if (ratio < 1) {
                this.changeBackground()
            }
          }, this.options);
        this.observer.observe(this.slides)
    }
    changeBackground() {
       document.body.classList.add('body--white')
    }

}

export default QuoteObserver;
